import React, { useEffect, useState,useContext } from "react";
import LayoutComponent from "../../components/shared/Layout";
import HeadContainer from "./HeadContainer";
import CasinoFilter from "./CasinoFilter";
import GameCard from "../../components/GameCard";
import {
  gamesLiveArray,
} from "../../constraints/constants";
import { useParams, useSearchParams ,useNavigate} from "react-router-dom";
import { compact, head, isEmpty, startCase } from "lodash";
import AuthContext from "../../context/AuthContext";


const Casino = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {amounutRefresh,user} = useContext(AuthContext);
  const [header, setHeader] = useState("popular");
  const [subCasino, setSubCasino] = useState("SEXY");
  const [casinoFilter, setCasinoFilter] = useState("catalog");
  const [popular, setPopular] = useState([]);
  const [liveArray, setLiveArray] = useState({
    gameShows: [],
    dice: [],
    traditional: [],
    roulette: [],
    baccarat: [],
    slot: [],
  });
  const [tableArray, setTabelArray] = useState([]);
 
  useEffect(() => {
    if (!isEmpty(header)) {
      let obj = {
        live: "SEXY",
        table: "KINGMAKER",
        slot: "PP",
        fishing: "JILI",
        egame: "PP",
        lottery: "KINGMAKER",
      };
      if (header == "live" || header == "popular") {
        setCasinoFilter("catalog");
      } else {
        casinoFilterHandel("latest");
      }
      setSubCasinoHandel(searchParams.get("subCasino")?searchParams.get("subCasino"):obj[header], header);
      if (header == "popular") {
        let temp = [...gamesLiveArray];
        let arr = temp?.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        const result =
          arr?.length > 0 &&
          arr.reduce((acc, word) => {
            const firstLetter = word?.name[0]?.toLowerCase();
            if (!acc[firstLetter]) {
              acc[firstLetter] = [];
            }
            acc[firstLetter].push(word);
            return acc;
          }, {});
        setPopular(
          Object.keys(result).map(function (key) {
            return { name: key, array: result[key] };
          })
        );
      }
    }
  }, [header]);
  
  const setSubCasinoHandel = (value, type = header) => {
    if (type !== "popular") {
      setSubCasino(value);
    }
    if (
      type == "table" ||
      type == "slot" ||
      type == "fishing" ||
      type == "lottery" ||
      type == "egame"
    ) {
      setTabelArray(
        gamesLiveArray?.filter((res) => {
          return (
            res?.cateogry == value &&
            res?.type ==
              (type == "table"
                ? "TABLE"
                : type == "slot"
                ? "SLOT"
                : type == "fishing"
                ? "FISHING"
                : type == "lottery"
                ? "LOTTERY"
                : type == "egame"
                ? "EGAME"
                : "")
          );
        }) || []
      );
    }
    if (type == "live") {
      setLiveArray({
        gameShows:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "GAME-SHOWS";
          }) || [],
        dice:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "DICE";
          }) || [],
        traditional:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "TRADITIONAL";
          }) || [],
        roulette:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "ROULETTE";
          }) || [],
        slot:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "SLOT";
          }) || [],
        baccarat:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "BACCARAT";
          }) || [],
        cardGames:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "GAME-CARD";
          }) || [],
        table:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "TABLE";
          }) || [],
        fishing:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "FISHING";
          }) || [],
        egame:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "EGAME";
          }) || [],
          lottery:
          gamesLiveArray?.filter((res) => {
            return res?.cateogry == value && res?.type == "LOTTERY";
          }) || [],
      });
    } else if (type == "popular") {
      setLiveArray({
        gameShows:
          gamesLiveArray?.filter((res) => {
            return res?.type == "GAME-SHOWS";
          }) || [],
        dice:
          gamesLiveArray?.filter((res) => {
            return res?.type == "DICE";
          }) || [],
        traditional:
          gamesLiveArray?.filter((res) => {
            return res?.type == "TRADITIONAL";
          }) || [],
        roulette:
          gamesLiveArray?.filter((res) => {
            return res?.type == "ROULETTE";
          }) || [],
        baccarat:
          gamesLiveArray?.filter((res) => {
            return res?.type == "BACCARAT";
          }) || [],
        cardGames:
          gamesLiveArray?.filter((res) => {
            return res?.type == "GAME-CARD";
          }) || [],
        table:
          gamesLiveArray?.filter((res) => {
            return res?.type == "TABLE";
          }) || [],
        others:
          gamesLiveArray?.filter((res) => {
            return res?.type == "OTHERS";
          }) || [],
        slot:
          gamesLiveArray
            ?.filter((res) => {
              return res?.type == "SLOT";
            })
            ?.slice(0, 30) || [],
        fishing:
          gamesLiveArray?.filter((res) => {
            return res?.type == "FISHING";
          }) || [],
        egame:
          gamesLiveArray?.filter((res) => {
            return res?.type == "EGAME";
          }) || [],

          lottery:
          gamesLiveArray?.filter((res) => {
            return  res?.type == "LOTTERY";
          }) || [],
      });
    }
  };
  const setheaderChange = (value) => {
    navigate("/casino")
    setHeader(value);
    if (value == "live") {
      setCasinoFilter("catalog");
      setSubCasino("SEXY");
    }
    if (value == "popular") {
      setCasinoFilter("catalog");
    }
    if (value == "table") {
      setCasinoFilter("latest");
      setSubCasino("KINGMAKER");
    }
    if (value == "slot") {
      setCasinoFilter("latest");
      setSubCasino("PP");
    }
    if (value == "fishing") {
      setCasinoFilter("latest");
      setSubCasino("SPADE");
    }
    if (value == "egame") {
      setCasinoFilter("latest");
      setSubCasino("PP");
    }
    if (value == "lottery") {
      setCasinoFilter("latest");
      setSubCasino("KINGMAKER");
    }
  };

  const casinoFilterHandel = (value) => {
    setCasinoFilter(value);
    if (header !== "live" || header !== "popular") {
      if (value == "az") {
        setTabelArray(
          gamesLiveArray
            ?.filter((res) => {
              return (
                res?.cateogry == subCasino &&
                res?.type ==
                  (header == "table"
                    ? "TABLE"
                    : header == "slot"
                    ? "SLOT"
                    : header == "fishing"
                    ? "FISHING"
                     : header == "lottery"
                    ? "LOTTERY"
                    : header == "egame"
                    ? "EGAME"
                    : "")
              );
            })
            ?.sort(function (a, b) {
              return a.name.localeCompare(b.name);
            })
        );
      } else if (value == "latest") {
        setSubCasinoHandel(subCasino, header);
      }
    }
  };
  useEffect(() => {
   
    if (!isEmpty(searchParams.get("header"))) {
      setHeader(searchParams.get("header"));
    }
    if (!isEmpty(searchParams.get("subCasino"))) {
      setSubCasino(searchParams.get("subCasino"));
    }
    setSubCasinoHandel(searchParams.get("subCasino"), searchParams.get("header"));

  }, [searchParams.get("header"),searchParams.get("subCasino")]);

  useEffect(() => {
    if(!isEmpty(user)){
      amounutRefresh();
    }
   
  }, [user]);
  
  return (
    
    <div>
      <LayoutComponent visibilityType={true}>
        <HeadContainer
          header={header}
          setHeader={setheaderChange}
          subCasino={subCasino}
          setSubCasino={setSubCasinoHandel}
          homclass={props?.homclass}
        />
        <div className="main-casino-wrapper">
          <CasinoFilter
            header={header}
            casinoFilter={casinoFilter}
            setCasinoFilter={casinoFilterHandel}
          />
          <div className="games-wrapper casino-all-games">
            {(header == "table" ||
              header == "slot" ||
              header == "fishing" ||
              header == "lottery" ||
              header == "egame") &&
              tableArray?.length > 0 && (
                <div className="games-card-inner ">
                  <div className="row">
                    {tableArray?.map((item, index) => {
                      return <GameCard key={index} res={item} />;
                    })}
                  </div>
                </div>
              )}
            {header == "live" || header == "popular" ? (
              casinoFilter == "catalog" ? (
                <>
                  {liveArray?.gameShows?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Game Shows</h2>
                      <div className="row">
                        {liveArray?.gameShows?.map((item, index) => {
                          return <GameCard key={index} res={item} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.baccarat?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Baccarat</h2>
                      <div className="row">
                        {liveArray?.baccarat?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.roulette?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Roulette</h2>
                      <div className="row">
                        {liveArray?.roulette?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.dice?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Dice</h2>
                      <div className="row">
                        {liveArray?.dice?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.traditional?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Traditional Games</h2>
                      <div className="row">
                        {liveArray?.traditional?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.cardGames?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Card Games</h2>
                      <div className="row">
                        {liveArray?.cardGames?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.others?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Others</h2>
                      <div className="row">
                        {liveArray?.others?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.table?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Table</h2>
                      <div className="row">
                        {liveArray?.table?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.slot?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Slot</h2>
                      <div className="row">
                        {liveArray?.slot?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.fishing?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Fishing</h2>
                      <div className="row">
                        {liveArray?.fishing?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                  {liveArray?.egame?.length > 0 && (
                    <div className="games-card-inner ">
                      <h2>Egame</h2>
                      <div className="row">
                        {liveArray?.egame?.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  )}
                </>
              ) : casinoFilter == "latest" ? (
                <div className="games-card-inner ">
                  <div className="row">
                    {[
                      ...liveArray?.gameShows,
                      ...liveArray?.baccarat,
                      ...liveArray?.roulette,
                      ...liveArray?.dice,
                      ...liveArray?.traditional,
                      ...liveArray?.cardGames,
                      ...liveArray?.table,
                      ...liveArray?.fishing,
                      ...liveArray?.lottery,
                      ...liveArray?.egame,
                    ]?.map((item, index) => {
                      return <GameCard key={index} res={item} />;
                    })}
                  </div>
                </div>
              ) : header == "popular" && casinoFilter == "az" ? (
                popular?.map((item) => {
                  return (
                    <div className="games-card-inner ">
                      <h2>{startCase(item?.name)}</h2>
                      <div className="row">
                        {item?.array.map((res, index) => {
                          return <GameCard key={index} res={res} />;
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="games-card-inner ">
                  <div className="row">
                    {compact([
                      liveArray?.gameShows?.length > 0 && liveArray?.gameShows,
                      liveArray?.baccarat?.length > 0 && liveArray?.baccarat,
                      liveArray?.roulette?.length > 0 && liveArray?.roulette,
                      liveArray?.dice?.length > 0 && liveArray?.dice,
                      liveArray?.traditional?.length > 0 &&
                        liveArray?.traditional,
                      liveArray?.cardGames?.length > 0 && liveArray?.cardGames,
                      liveArray?.table?.length > 0 && liveArray?.table,
                      liveArray?.fishing?.length && liveArray?.fishing,
                      liveArray?.egame?.length && liveArray?.egame,
                      liveArray?.slot?.length && liveArray?.slot,
                      liveArray?.lottery?.length && liveArray?.lottery,
                      liveArray?.others?.length && liveArray?.others,
                    ])?.flat()
                      ?.sort(function (a, b) {
                        return a?.name?.localeCompare(b.name);
                      })
                      ?.map((item, index) => {
                        return <GameCard key={index} res={item} />;
                      })}
                  </div>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default Casino;
