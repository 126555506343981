import { isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import BackLayHome from "./BackLayHome";
import AuthContext from "../context/AuthContext";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import BetSlipContext from "../context/BetSlipContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { priceFormat } from "../Utils/constants";
import useScroll from "./Scroll";
const SportAccordianInnerFav = ({
  matchOdds,
  object,
  keyTime,
  index,
  addToFavioute,
  activeFav,
  setActiveFav,scrollHeight
}) => {
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const { user,profileData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [runner, setRunner] = useState([]);
  const [select, setSelect] = useState({});
  const {
    setBetSlipObject,
    setShowBetDialog,
    showBetDialog,
    betSlipObject,
    resetBetPosition,
    resetBetSlipObject,
    setResetBetPosition,
    betPlace,
  } = useContext(BetSlipContext);

  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    if (!isEmpty(profileData)) {
      setLocalLimit(
        profileData?.matchSetting?.find((res) => {
          return res?.type == "betFaire" && res?.sportType == object?.gameType;
        })
      );
    }
  }, [profileData]);

  useEffect(() => {
    setRunner(object?.runners ||[]);
    getBetPosition(object?.eventId);
  }, []);

  const getBetPosition = async (item) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition + `?eventId=${item}`
    );
    if (status === 200) {
      if (response_users.success) {
        setRunner((current) =>
          response_users?.results?.betFair?.length > 0
            ? current.map((res) => {
                let obj = response_users?.results?.betFair.find(
                  (item) => item?.selectionId == res?.SelectionId
                );
                return {
                  ...res,
                  position:
                    obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                  newPosition: 0,
                };
              })
            : current.map((res) => {
                return {
                  ...res,
                  position: 0,
                  newPosition: 0,
                };
              })
        );
      }
    }
  };

  const handelChange = (odds, type, item, object) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        // setLimit(localLimit);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: item,
          betType: type,
          playType: "betFair",
          object: {
            ...item,
            ...odds,
            teamName: item?.RunnerName,
            eventId: object?.eventId,
            marketId: object?.marketId,
          },
        }));
      }
    } else {
      navigate("/login");
    }
  };

  const handelBetFairPositions = (slipObj) => {
    let selectionsTemp = [...runner];
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      getBetPosition(object?.eventId);
    } else if (betPlace?.isPlaced && betPlace?.type == "error") {
      setResetBetPosition(false);
      resetBetSlipObject();
      setRunner((prev) => {
        return prev.map((res) => {
          return { ...res, newPosition: 0 };
        });
      });
    }
  }, [betPlace]);

  useEffect(() => {
    if (resetBetPosition) {
      setResetBetPosition(false);
      resetBetSlipObject();
      setRunner((prev) => {
        return prev.map((res) => {
          return { ...res, newPosition: 0 };
        });
      });
    }
  }, [resetBetPosition]);

  useEffect(() => {
    if (showBetDialog) {
      if (!isEmpty(betSlipObject)) {
        if (betSlipObject?.playType == "betFair") {
          handelBetFairPositions(betSlipObject, runner);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  return (
    <div className="sport-accord-body sport-fav">
      <div className="position-relative">
        <div
          onClick={() =>
            navigate(`/full-market/${object?.marketId}/${object?.eventId}`)
          }
          style={{ width: "60%" }}
          className={"ps-4 sport-fav-match"}
        >
          <span>{object?.eventName}</span>
        </div>

        <div
          className={
            "d-flex justify-content-between align-items-center  bg-primary-odds st"
          }
        >
          <div
            className={`fave  bg-star-sprit-black  position-absolute start-0 ${
              activeFav?.includes(object?.eventId) ? "animate" : ""
            }`}
            style={{
              marginLeft: "-27px",
              marginTop: "-16px",
            }}
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              } else {
                if (object?.hasMatchOdds) {
                  addToFavioute(object?.eventId,object?.marketId,"Match Odds",object?.eventType);
                }
              }
              if (object?.hasMatchOdds) {
                if (activeFav?.includes(object?.eventId)) {
                  setActiveFav((prev) => {
                    return prev.filter((item) => {
                      return item !== object?.eventId;
                    });
                  });
                } else {
                  setActiveFav((prev) => {
                    return [...prev, object?.eventId];
                  });
                }
              }
            }}
          ></div>

          <div
            className="half-table"
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h6 className="text-left">Matched</h6>
            <strong className="px-2">
              {matchOdds?.totalMatched > 0 &&
                `${priceFormat(matchOdds?.totalMatched)}`}
            </strong>
            {matchOdds?.totalMatched < 10000 && (
              <strong className="bg-danger rounded text-white low-button">
                Low
              </strong>
            )}
          </div>
          <div className="d-flex align-items-center">
            <Button className="bg-transparent text-dark border-0 fs-4 px-2 pt-0 pe-0">
              <FaAngleRight />
            </Button>
          </div>
        </div>

        {runner?.length > 0 &&
          !isEmpty(matchOdds) &&
          object?.marketId == matchOdds?.mi && (
            <div className="sports-team-table">
              <div className="match-odd-table bg-white p-3 px-0 py-1">
                <div className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100 mb-1">
                  {/* <div
                    className="half-table"
                   
                  >
                    <h4 className="text-left" style={{ marginRight: "5px" }}>
                    
                    </h4>
                  </div> */}
                  <span
                    className="text-center sport-header-inner"
                    style={{ width: "65px" }}
                  >
                    Back
                  </span>
                  <span
                    className="text-center sport-header-inner"
                    style={{ width: "65px" }}
                  >
                    Lay
                  </span>
                </div>
                <ul className="mb-2 rounded position-relative p-0">
                  {runner?.map((newObj) => {
                    const odds_back = matchOdds?.rt
                      ?.filter((rt) => {
                        return rt.ib;
                      })
                      ?.filter((todd) => todd.ri == newObj.SelectionId);
                    const odds_lay = matchOdds?.rt
                      ?.filter((rt) => {
                        return !rt.ib;
                      })
                      ?.filter((todd) => todd.ri == newObj.SelectionId);
                    const odds_lay2 =
                      odds_lay?.find(
                        (todd) => todd.ri === newObj.SelectionId
                      ) || {};
                    let odds_back2 =
                      odds_back?.find(
                        (todd) => todd.ri === newObj.SelectionId
                      ) || {};
                    let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
                    let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
                    let disable = false;
                    if (odds_lay?.length == 0 && odds_back?.length == 0) {
                      disable = true;
                    } else if (layRt <= 0 && backRt <= 0) {
                      disable = true;
                    } else if (
                      (layRt > localLimit?.oddsLimit || layRt == 0) &&
                      (layRt > localLimit?.oddsLimit || backRt == 0)
                    ) {
                      disable = true;
                    } else {
                      disable = false;
                    }
                    return (
                      <li
                        className="position-relative"
                        style={{ scrollMarginTop: scrollHeight }}
                        ref={index == scrollId ? myRef : null}
                      >
                        <h2>
                          {newObj?.RunnerName}{" "}
                          {(Math?.abs(newObj?.position) > 0 ||
                            Math.abs(newObj?.newPosition) > 0) && (
                            <span
                              className={`d-block py-1 ${
                                newObj?.position > 0
                                  ? "text-green team-red-score"
                                  : "team-red-score"
                              }`}
                            >
                              {Math?.abs(newObj?.position) > 0
                                ? newObj?.position > 0
                                  ? Math?.abs(newObj?.position).toFixed(2)
                                  : `( ${Math.abs(newObj?.position).toFixed(
                                      2
                                    )} )`
                                : ""}
                              {object?.eventId ==
                                betSlipObject?.object?.eventId &&
                                Math.abs(newObj?.newPosition) > 0 && (
                                  <span
                                    className={
                                      newObj?.newPosition > 0
                                        ? "text-green"
                                        : ""
                                    }
                                  >
                                    {" "}
                                    {">"}{" "}
                                    {newObj?.newPosition > 0
                                      ? Math.abs(newObj?.newPosition).toFixed(2)
                                      : `( ${Math.abs(
                                          newObj?.newPosition
                                        ).toFixed(2)} )`}
                                  </span>
                                )}
                            </span>
                          )}
                        </h2>
                        <BackLayHome
                          component={"betfair"}
                          type="sport"
                          index={index}
                          item={newObj}
                          object={object}
                          setSelect={setSelect}
                          handelChange={handelChange}
                          odds_lay={odds_lay}
                          odds_back={odds_back}
                          //   limit={localLimit}
                        />{" "}
                        {disable && matchOdds?.ms == 1 && (
                          <div className="overlay-match">Suspended</div>
                        )}
                      </li>
                    );
                  })}
                  {matchOdds?.ms != 1 && (
                    <div className="overlay-match">Suspended</div>
                  )}
                </ul>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default SportAccordianInnerFav;
