import React from "react";
import { Button, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
const MarketDepth = ({ setShowMarketDepthPopup }) => {
  return (
    <div className="market-depth-modal slide-up">
      <div className="market-title">
        <h4>Market Depth</h4>
        <Button className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"  onClick={() => setShowMarketDepthPopup(false)}>
          <RxCross2 />
        </Button>
      </div>

      <div className="market-depth-body">
        <div className="match-point">
          {" "}
          <span className="master-pass">Sri Lanka v Afghanistan</span>
          <span className="master-pass">Match Odds</span>
        </div>

        <div className="select-container">
          <DropdownButton id="abc" title="Sri Lanka" className="">
            <Dropdown.Item href="/">Netherlands</Dropdown.Item>
          </DropdownButton>
        </div>

        <Table className="mt-3 border">
          <tbody>
            <tr>
              <td className="text-center">
                Total matched
                <strong class="d-block">26855.14</strong>
              </td>
              <td className="text-center">
                Selection Volume
                <strong class="d-block">26547.56</strong>
              </td>
              <td className="text-center">
                Last price
                <strong class="d-block">1.13</strong>
              </td>
            </tr>
          </tbody>
        </Table>

        <div className="balance-label position-relative  exchange-label">
          Price, Exchange Available and Traded
        </div>

        <div className="d-flex justify-content-between mt-3 px-3 mb-3">
          <span className="bet-name lay rounded fw-normal ps-2">
            <FaAngleLeft className="text-success fs-5" />
            to Back
          </span>
          <span className="bet-name back rounded fw-normal pe-2">
            to Lay
            <FaAngleRight className="text-danger" />
          </span>
        </div>

<div className="to-back-to-lay">
<Table className="">
      <thead>
      <tr>
          <th className="fw-normal text-center bet-name lay rounded-0">
            <strong className="d-block">1.11</strong>
            219.2
          </th>
        <th className="fw-normal text-center bet-name lay rounded-0">
            <strong className="d-block">1.11</strong>
            219.2
          </th>
        <th className="fw-normal text-center bet-name lay rounded-0">
            <strong className="d-block">1.11</strong>
            219.2
          </th>
        <th className="fw-normal text-center bet-name back rounded-0">
            <strong className="d-block">1.11</strong>
            219.2
          </th>
        <th className="fw-normal text-center bet-name back rounded-0">
            <strong className="d-block">1.11</strong>
            219.2
          </th>
        <th className="fw-normal text-center bet-name back rounded-0">
            <strong className="d-block">1.11</strong>
            219.2
          </th>
        </tr>
      </thead>
      <tbody>


        <tr>
          <td>1553.25</td>
          <td>1553.25</td>
          <td>1553.25</td>
          <td>1553.25</td>
          <td>1553.25</td>
          <td>1553.25</td>
        </tr>

      </tbody>
    </Table>
</div>


      </div>
    </div>
  );
};

export default MarketDepth;
