import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import {
  DepositWithrawData,
  DepositWithrawDataNew,
  bottomNavigation,
} from "../Utils/constants";

import BetSlipContext from "../context/BetSlipContext";
import MyBets from "./MyBets";
import betsIcon from "../assets/footer-icons/mybets.png";
import svgLeft from "../assets/images/svg-left.svg";
import svgRight from "../assets/images/svg-right.svg";
import betsvgLeft from "../assets/images/svg-left-bet.svg";
import betsvgRight from "../assets/images/svg-right-bet.svg";
const FooterNew = () => {
  const { user, lang, setEn, setBr, handleShowLoginNotification,setShowWidget,showWidget, setShowParlyBet,widgetsData,
    showParlyBet,
    parly,
    parlyBet, } =
    useContext(AuthContext);
  let location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showBet, setShowBet } = useContext(BetSlipContext);

  return (
    <>
      { !isEmpty(user)  &&
      <div
      
       className="s-float-button"
      
      >
        <div className={showWidget && widgetsData.length>0 && ["full-market", "sports"]?.includes(location?.pathname?.split("/")[1])?"s-items-center bg-betSlip-icon-light2 ":"s-items-center "} style={{zIndex:`999999`}}>
       
         {["full-market", "sports"]?.includes(
            location?.pathname?.split("/")[1]
          ) &&
            parly &&
            !isEmpty(user) &&
            parlyBet?.length > 0 && (
  <div   className="bg-betSlip-icon-light bg-yellow" style={{zIndex:`999999`}}>
         
         <span class="s-icon-list"  onClick={() => {
                  setShowParlyBet(true);
                }}>
           <img className="svg-left" src={betsvgLeft} />
          
              <>
              <i  class="icon-parlay-list parlay-betsslip"  ></i>
               
                <span className="parlay-c">{parlyBet?.length}</span>
                </>
           
           <img className="svg-right" src={betsvgRight} />
         </span>
        
       </div>
        )}
          <div  className="bg-betSlip-icon-light"   onClick={() => {
          if (!isEmpty(user)) {
            setShowBet(true);
          } else {
            navigate("/login");
          }
        }}>
         
            <span class="s-icon-list">
              <img className="svg-left" src={svgLeft} />
              <img src={betsIcon} />
              <img className="svg-right" src={svgRight} />
            </span>
           
          </div>
        </div>
      </div>
      }
      <div className="footer-new">
        <ul>
          {/*         
        {isEmpty(user) ? (
          <>
          <li className="language-select">
          <div
            className="language-select-div"
            onClick={() => {
              if (lang == "bn") {
                setEn();
              } else {
                setBr();
              }
            }}
          >
            <img src={lang == "bn" ? FlagIcon : FlagIconBn} alt="" />{" "}
            {lang == "bn" ? "INR" : "BDT"} <br />{" "}
            {lang == "bn" ? "English" : "Bangla"}
          </div>
        </li>
        <li className="register-button">
              <Link to="/register">{t("Sign_up")}</Link>
            </li>
            <li className="login-button">
              <Link to="/login">{t("Login")}</Link>
            </li>
          </>
        ) : ( */}
          <>
            {/* {bottomNavigation && bottomNavigation?.map((item, index) => {
              return (
            <li className={location.pathname === item?.link ? "active" : ""}
            onClick={() => {
              if (item?.id == 5) {
                if (!isEmpty(user)) {
                  setShowBet(true);
                 
                } else {
                  handleShowLoginNotification();
                }
              } else {
                navigate(item?.link);
              }
            }}>
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={location.pathname === item?.link?item?.iconActive:item?.icon} />
                </figure>
                <span>{item?.menu}</span>
              </div>
            </li>
              );
          })} */}
          </>
          {/* )} */}
        </ul>
        {showBet && <MyBets setShowBet={setShowBet} />}
      </div>
    </>
  );
};

export default FooterNew;
