import React, { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const BackLayHome = ({
  component,
  handelChange,
  odds_lay,
  odds_back,
  index,
  item,
  limit,
  marketName,
  type,
  disabelBack,
  disabelLay,
  object
}) => {
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  let backClass = `back ${
    Number(
      document
        .getElementById("back_odds_" + item?.SelectionId + index)
        ?.title?.split("-")[0]
        ?.trim()
    ) !== odds_back[0]?.rt ||
    Number(
      document
        .getElementById("back_odds_" + item?.SelectionId + index)
        ?.title?.split("-")[1]
        ?.trim()
    ) !== odds_back[0]?.bv
      ? "spark-back"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_back[0]?.rt &&
    showBetDialog
      ? "back-active"
      : ""
  }`;
  let layClass = `lay ${
    Number(
      document
        .getElementById("lay_odds_" + item?.SelectionId + index)
        ?.title?.split("-")[0]
        ?.trim()
    ) !== odds_lay[0]?.rt ||
    Number(
      document
        .getElementById("lay_odds_" + item?.SelectionId + index)
        ?.title?.split("-")[1]
        ?.trim()
    ) !== odds_lay[0]?.bv
      ? "spark-lay"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_lay[0]?.rt &&
    showBetDialog
      ? "lay-active"
      : ""
  }`;
  
  let obj = moment.duration(
    moment(object?.eventDateTime).diff(moment(new Date()))
  )?._data;

  let checkDisabled = (odds) => {
    if (Number(odds) > 0) {
      if (Number(odds) > limit?.oddsLimit ) {
        return true;
      } else if(obj?.hours>2 || obj?.days>=1){
        return true;
      }
      else {
        return false;
      }
    } else {
      return true;
    }
  };

  const navigate = useNavigate();

  return (
    <div
      className={`lay-back-table ${
        component == "bookmaker" ? "bg-bookmaker-market" : ""
      }`}
    >
      <div
        title={`${odds_back[0]?.rt}-${odds_back[0]?.bv}`}
        id={"back_odds_" + item?.SelectionId + index}
        onClick={(e) => {
          if (component == "betfair" && disabelBack) {
          } else {
            if (!checkDisabled(odds_back[0]?.rt)) {
              handelChange(
                odds_back[0],
                "back",
                item?.RunnerName,
                object,
                index
              );
            }
          }
          e.stopPropagation();
        }}
        className={backClass}
      >
        <span className={checkDisabled(odds_back[0]?.rt)?"single-disabled":""}>
          <strong>{odds_back[0]?.rt || ""}</strong>
          <span className="d-block">{odds_back[0]?.bv || ""}</span>
        </span>
      </div>
      <div
        title={`${odds_lay[0]?.rt}-${odds_lay[0]?.bv}`}
        id={"lay_odds_" + item?.SelectionId + index}
        onClick={(e) => {
          if (component == "betfair" && disabelLay) {
          } else {
            if (!checkDisabled(odds_lay[0]?.rt)) {
              handelChange(odds_lay[0], "lay", item?.RunnerName, object, index);
            }
          }
          e.stopPropagation();
        }}
        className={layClass}
      >
        <span className={checkDisabled(odds_lay[0]?.rt)?"single-disabled":""}>
          <strong>{odds_lay[0]?.rt || ""}</strong>
          <span className="d-block">{odds_lay[0]?.bv || ""}</span>
        </span>
      </div>
    </div>
  );
};

export default BackLayHome;
