import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { FaAngleLeft } from "react-icons/fa";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
const Leagues = () => {
  const navigate = useNavigate();
  const { gameTab, setGameTab } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [selected, setSelected] = useState({});
  const getData = async () => {
    const { status, data } = await apiGet(apiPath.seriesList+"?domain=bajifair.live");
    if (data?.success) {
      setList(data?.results);
      setUpdatedList(() => {
        if (gameTab == "all") {
          return filteredArray(data?.results);
        } else {
          return filteredArray(
            data?.results?.filter((res) => {
              return res?.gameType == gameTab;
            })
          );
        }
      });
    }
  };

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setUpdatedList(() => {
      if (gameTab == "all") {
        return list;
      } else {
        return list?.filter((res) => {
          return res?.gameType == gameTab;
        });
      }
    });
  }, [gameTab]);
  return (
    <div>
      {" "}
      <LayoutComponent visibilityType={true}>
        {isEmpty(selected) && (
          <div>
            <GameMenu game={gameTab} setGame={setGameTab} />
            {updatedList?.length > 0 ? (
              <div className="d-flex p-3 leage-list main-casino-wrapper">
                {/* <aside className="text-center">
                <ul className="p-0 mb-0">
                  <li>C</li>
                  <li>I</li>
                  <li>O</li>
                </ul>
              </aside> */}
                <div
                  className={`main-list w-100 ${
                    updatedList?.length > 7 ? "mb-5" : ""
                  }`}
                >
                  <h6 className="mb-3">Popular</h6>
                  {updatedList?.map((item, index) => {
                    return (
                      <div className="w-100 mb-3 match-list">
                        <ul className="p-0 m-0">
                          <li onClick={() => setSelected(item)}>
                          <i  class="icon-top"></i>  {item?.seriesName}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="d-flex p-3 justify-content-center align-items-center leage-list main-casino-wrapper">
                <div id="loading" className="loading" style={{display:`flex`}}>
                <div className="loading-box">
                  <div className="loading-spinner"></div>
                </div>
              </div>
              </div>
            )}
          </div>
        )}

        {/* second-level */}
        {!isEmpty(selected) && (
          <div className="second-level-leage">
            <div className="balance-label position-relative text-start ps-5">
              <button
                onClick={() => setSelected({})}
                className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
              >
                <FaAngleLeft className="fs-3" />
              </button>
              {selected?.seriesName}
            </div>

            <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
              <div
                className={`main-list w-100 ${
                  selected?.matchList?.length > 7 ? "mb-5" : ""
                }`}
              >
                {selected?.matchList?.map((item, index) => {
                  return (
                    <div className="w-100 mb-3 match-list">
                      <ul className="p-0 m-0">
                        <li
                          onClick={() =>
                            navigate(
                              `/full-market/${item?.marketId}/${item?.eventId}`
                            )
                          }
                        >
                          {item?.runners?.map((res) => {
                            return (
                              <span className="d-block">{res?.RunnerName}</span>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {/* second-level */}
      </LayoutComponent>
    </div>
  );
};

export default Leagues;
