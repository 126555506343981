import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import SportAccordian from "../components/SportAccordian";
import {
  Accordion,
  Button,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
import { io } from "socket.io-client";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import SearchBar from "../components/SearchBar";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty, startCase } from "lodash";
import { createPortal } from "react-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleRight, FaArrowsAltV } from "react-icons/fa";
import BackLay from "../components/BackLay";
import SportAccordianInner from "../components/SportAccordianInner";
import SportAccordianInnerFav from "../components/SportAccordianInnerFav";
import logo from "../assets/images/loader.gif";
const Favourite = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
  } = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loader, setLoader] = useState(false);
  const [odds, setOdds] = useState([]);
  const [list, setList] = useState([]);
  const [activeFav, setActiveFav] = useState([]);

  const addToFavioute = async (id, favoriteMarketId,favoriteType,eventType) => {
    const { status, data: response_users } = await apiGet(
       apiPath.addToFavourite + `?eventId=${id}&favoriteMarketId=${favoriteMarketId}&favoriteType=${favoriteType}&eventType=${eventType}`
    );
    getData();
  };

  const getData = async () => {
    setLoader(true);
    const { status, data } = await apiGet(apiPath.favourtieList);
    if (data?.success) {
      setList(data?.results);
      setActiveFav(
        data?.results?.map((res) => {
          return res?.eventId;
        })
      );
      getMatchOddsMulti(
        data?.results?.map((res) => {
          return res?.marketId;
        })
      );
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };
  useEffect(() => {
    getData();
  }, [gameTab, keyTime]);

  const [scrollHeight, setScrollHeight] = useState(50);
  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight)
      : 0;
    setScrollHeight(header);
  }, []);
  return (
    <div>
      <LayoutComponent >
        {loader ? (
          <div>
            <div className="bkash-loader apiloader" >
      <img src={logo} loop autoPlay muted style={{ height: `150px` }} />
    </div>
          </div>
        ) : list?.length > 0 ? (
          <div className="bg-skylight p-3">
            {list?.length > 0 ? (
              list?.map((object, index) => {
                let matchOdds =
                  odds?.find((res) => {
                    return res?.mi == object?.marketId;
                  }) || {};
                return (
                  <SportAccordianInnerFav
                    index={index}
                    keyTime={keyTime}
                    matchOdds={matchOdds}
                    object={object}
                    activeFav={activeFav}
                    setActiveFav={setActiveFav}
                    addToFavioute={addToFavioute}
                    scrollHeight={scrollHeight}
                  />
                );
              })
            ) : (
              <NoEvent />
            )}
          </div>
        ) : (
          <NoEvent />
        )}
      </LayoutComponent>
    </div>
  );
};

export default Favourite;
