import React, { useContext, useEffect, useState } from "react";
import { Button, Table, Accordion ,Dropdown, DropdownButton,} from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import OutsideClickHandler from "react-outside-click-handler";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { isEmpty, startCase } from "lodash";
import obj from "../Utils/helpers";
import objConstant from "../Utils/constants";
import { FaAngleLeft } from "react-icons/fa";
import { BiSolidRightArrow } from "react-icons/bi";
import AuthContext from "../context/AuthContext";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import BetSlipContext from "../context/BetSlipContext";

const MyBets = ({ setShowBet }) => {
  const [show, setShow] = useState(true);
  const { showBet } = useContext(BetSlipContext);

  const { currentBetCount, getCurrentBetCount } = useContext(AuthContext);
  const [singleBet, SetSingleBet] = useState(false);
  const [data, setData] = useState([]);
  const [parly, setParly] = useState([]);
  const [showBetNew, setShowBetNew] = useState("");
  const [selected, setSelected] = useState("exchange");
  const [activeKey, setActiveKey] = useState("0")
  const handleSingleBet = (eventName, type, list) => {
    SetSingleBet({
      eventName: eventName,
      type: type,
      list: list,
    });
  };

  const handleShowBet = (data) => {
    if (data == showBetNew) {
      setShowBetNew("");
    } else {
      setShowBetNew(data);
    }
  };
  const getCurrentBets = async () => {
    getCurrentBetCount();
    const { status, data: response_users } = await apiGet(
      apiPath.mobileCurrentBets
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  const getParlyBet = async () => {
    const { status, data } = await apiGet(apiPath.getParlyBets);
    if (status == 200) {
      if (data?.success) {
        setParly(data?.results);
      }
    }
  };
  
  // useEffect(() => {
  //   getCurrentBets();
  //   getParlyBet();
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentBets();
      getParlyBet();
    }, 1000);

    return () => clearInterval(interval);
  }, [showBet]);


  return (
    // <OutsideClickHandler
    //   onOutsideClick={() => {
    //     setShowBet(false);
    //   }}
    // >
    <div className="market-depth-modal slide-up single-match-body-mybets-modal">
      <div className="market-title">
        {/* {!isEmpty(singleBet?.eventName) &&
          !isEmpty(singleBet?.type) &&
          singleBet?.list?.length > 0 ? (
          <>
            <button
              onClick={() => SetSingleBet({})}
              className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
            >
              <FaAngleLeft className="fs-3" />
            </button>
            <div>
              <span className="">{singleBet?.eventName}</span>
              <br />
               <span className="master-pass">{singleBet?.type}</span> 
            </div>
          </>
        ) : ( */}
          <h4 className="text-white">My Bets</h4>
        {/* )} */}
        <Button
          onClick={() => {setShowBet(false)}}
          className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
        >
          <RxCross2 />
        </Button>
      </div>
      {/* {isEmpty(singleBet?.eventName) && isEmpty(singleBet?.type) && ( */}
      <div
        style={{

        }}
        className="mybets-text"
      >
        <div
        className="mybets-text-title"
          style={{
            borderBottom:
              selected == "exchange" ? "4px solid #fff" : "none",
            paddingBottom: "6px",
            marginRight: "20px",
          }}
          onClick={() => setSelected("exchange")}
        >
          Exchange{" "}
          <span
            style={{ padding: "2px 5px" }}
            className="bg-dark text-white d-inline-block rounded"
          >
            {currentBetCount}
          </span>
        </div>
        <div
        className="mybets-text-title"
          onClick={() => setSelected("parly")}
          style={{
            borderBottom: selected == "parly" ? "4px solid #000" : "none",
            // paddingBottom: "6px",
          }}
        >
          Parly{" "}
          <span
            style={{ padding: "2px 5px" }}
            className="bg-dark text-white d-inline-block rounded"
          >
            {parly?.length}
          </span>
        </div>
      </div>
      {/* // )} */}
      <div className="all-complete">
              <DropdownButton
                id="abc"
                title={
                  <div>
                    {/* <i class="icon-toggle rotate-90"></i> */}
                    by event
                    {/* <i class="icon-triangle divtriangle"></i> */}
                  </div>
                }
                className=""
              >
                <Dropdown.Item>by Time</Dropdown.Item>
              </DropdownButton>
              {/* {gameTab !== "all" && ( */}
                <div class="all-complete-all">
                  <span class="mr-1">ALL</span>
                  {!show ? (
                    <MdKeyboardDoubleArrowDown />
                  ) : (
                    <MdKeyboardDoubleArrowUp />
                  )}
                </div>
              {/* )} */}
            </div>

      {selected == "exchange" ? (
        data?.length > 0 ? (
          <div className="market-depth-body single-match-body-mybets" style={{ borderRadius: "0" }} >

            {data?.length > 0
              ? data?.map((item, index) => {
                return (
                  <>
                    <Accordion defaultActiveKey={item?._id}>

                      {item?.betList?.length > 0 && (
                        <>
                          <Accordion.Item eventKey={item?._id} key={item?._id}>
                            <Accordion.Header 
                            // onClick={() =>
                            //   handleSingleBet(
                            //     item?.eventName,
                            //     "BetFair",
                            //     item?.betList
                            //   )
                            // }
                            >
                              {" "}
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.betList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">BetFair</span>
                              </div>



                            </Accordion.Header>
                            <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
                              {/* {!isEmpty(singleBet?.eventName) &&
                                !isEmpty(singleBet?.type) &&
                                singleBet?.list?.length > 0 ? ( */}
                                <div>

                                  <div className="current-bets-table p-2 border-0 single-match-body">
                                    {item?.betList?.map((item, index) => {
                                      return (
                                        <Table
                                          key={index}
                                          bordered
                                          className="rounded overflow-hidden bg-white mb-3 odds-table"
                                        >
                                          <tbody>
                                            <tr className="lay-back">
                                              <td colSpan="3">
                                                {" "}
                                                <span
                                                  className={`bet-name ${item?.type == "No"
                                                    ? "lay"
                                                    : item?.type == "Yes"
                                                      ? "back"
                                                      : item?.betType
                                                    }`}
                                                >
                                                  {startCase(item?.betType || item?.type)}{" "}
                                                </span>{" "}
                                                {item?.teamName || item?.fancyName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Odds.{" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {!isEmpty(item?.betType)
                                                    ? item?.bhav
                                                    : `${item?.betRun}/${item?.bhav}`}
                                                </strong>
                                              </td>
                                              <td>
                                                Stake (USD){" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.amount}
                                                </strong>
                                              </td>
                                              <td>
                                              {item?.betType == "back"? "Profit (USD)":"Liability (USD)" }
                                              {item?.betType == "back"?
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.profitAmount}
                                                </strong>
                                                :
                                                <strong className="d-block" style={{color:'red'}}>
                                                  {" "}
                                                 ({item?.profitAmount})
                                                </strong>}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="" colSpan="3">
                                                <div className="d-flex justify-content-between">
                                                  Ref:{" "}
                                                  {item?.matchBetId
                                                    ? item?.matchBetId
                                                    : item?.sessionBetId || "--"}
                                                  <span>
                                                    {obj.dateFormat(item?.timeInserted)}
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      );
                                    })}
                                  </div>
                                </div>
                              {/* ) : ""} */}
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )}


                    </Accordion>
                    <>
                    <Accordion defaultActiveKey={item?._id}>


                      {item?.bookmakerList?.length > 0 && (
                        <>
                          <Accordion.Item eventKey={item?._id} key={item?._id}>
                            <Accordion.Header onClick={() =>
                              handleSingleBet(
                                item?.eventName,
                                "Bookmaker",
                                item?.bookmakerList
                              )
                            }>
                              {" "}
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.bookmakerList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Bookmaker</span>
                              </div>



                            </Accordion.Header>
                            <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
                              {!isEmpty(singleBet?.eventName) &&
                                !isEmpty(singleBet?.type) &&
                                singleBet?.list?.length > 0 ? (
                                <div>

                                  <div className="current-bets-table p-2 border-0 single-match-body">
                                    {singleBet?.list?.map((item, index) => {
                                      return (
                                        <Table
                                          key={index}
                                          bordered
                                          className="rounded overflow-hidden bg-white mb-3 odds-table"
                                        >
                                          <tbody>
                                            <tr className="lay-back">
                                              <td colSpan="3">
                                                {" "}
                                                <span
                                                  className={`bet-name ${item?.type == "No"
                                                    ? "lay"
                                                    : item?.type == "Yes"
                                                      ? "back"
                                                      : item?.betType
                                                    }`}
                                                >
                                                  {startCase(item?.betType || item?.type)}{" "}
                                                </span>{" "}
                                                {item?.teamName || item?.fancyName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Odds.{" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {!isEmpty(item?.betType)
                                                    ? item?.bhav
                                                    : `${item?.betRun}/${item?.bhav}`}
                                                </strong>
                                              </td>
                                              <td>
                                                Stake (USD){" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.amount}
                                                </strong>
                                              </td>
                                              <td>
                                              {item?.betType == "back"? "Profit (USD)":"Liability (USD)" }
                                              {item?.betType == "back"?
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.profitAmount}
                                                </strong>
                                                :
                                                <strong className="d-block" style={{color:'red'}}>
                                                  {" "}
                                                 ({item?.profitAmount})
                                                </strong>}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="" colSpan="3">
                                                <div className="d-flex justify-content-between">
                                                  Ref:{" "}
                                                  {item?.matchBetId
                                                    ? item?.matchBetId
                                                    : item?.sessionBetId || "--"}
                                                  <span>
                                                    {obj.dateFormat(item?.timeInserted)}
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : ""}
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )}


                    </Accordion>
                    <Accordion defaultActiveKey="2">



                      {item?.sessionBetList?.length > 0 && (
                        <>
                          <Accordion.Item eventKey={item?.eventid} key={item?.eventid}>
                            <Accordion.Header onClick={() =>
                              handleSingleBet(
                                item?.eventName,
                                "Fancy",
                                item?.sessionBetList
                              )
                            }>
                              {" "}
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.sessionBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass">Fancy</span>
                              </div>



                            </Accordion.Header>
                            <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
                              {!isEmpty(singleBet?.eventName) &&
                                !isEmpty(singleBet?.type) &&
                                singleBet?.list?.length > 0 ? (
                                <div>

                                  <div className="current-bets-table p-2 border-0 single-match-body">
                                    {singleBet?.list?.map((item, index) => {
                                      return (
                                        <Table
                                          key={index}
                                          bordered
                                          className="rounded overflow-hidden bg-white mb-3 odds-table"
                                        >
                                          <tbody>
                                            <tr className="lay-back">
                                              <td colSpan="3">
                                                {" "}
                                                <span
                                                  className={`bet-name ${item?.type == "No"
                                                    ? "lay"
                                                    : item?.type == "Yes"
                                                      ? "back"
                                                      : item?.betType
                                                    }`}
                                                >
                                                  {startCase(item?.betType || item?.type)}{" "}
                                                </span>{" "}
                                                {item?.teamName || item?.fancyName}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Odds.{" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {!isEmpty(item?.betType)
                                                    ? item?.bhav
                                                    : `${item?.betRun}/${item?.bhav}`}
                                                </strong>
                                              </td>
                                              <td>
                                                Stake (USD){" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.amount}
                                                </strong>
                                              </td>
                                              <td>
                                              {item?.type == "Yes"? "Profit (USD)":"Liability (USD)" }
                                              {item?.type == "Yes"?
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.profitAmount}
                                                </strong>
                                                :
                                                <strong className="d-block" style={{color:'red'}}>
                                                  {" "}
                                                 ({item?.profitAmount})
                                                </strong>}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="" colSpan="3">
                                                <div className="d-flex justify-content-between">
                                                  Ref:{" "}
                                                  {item?.matchBetId
                                                    ? item?.matchBetId
                                                    : item?.sessionBetId || "--"}
                                                  <span>
                                                    {obj.dateFormat(item?.timeInserted)}
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : ""}
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )}



                    </Accordion>
                    <Accordion defaultActiveKey="3">



                      {item?.sportBookBetList?.length > 0 && (
                        <>
                          <Accordion.Item eventKey={item?.eventid} key={item?.eventid}>
                            <Accordion.Header onClick={() =>
                              handleSingleBet(
                                item?.eventName,
                                "SportsBook",
                                item?.sportBookBetList
                              )
                            }>
                              {" "}
                              <div>
                                <span className="bg-yellow px-2 py-1 rounded fw-bold fs-6">
                                  {item?.sportBookBetList?.length}
                                </span>
                                <span className="master-pass first">
                                  {item?.eventName}
                                </span>
                                <span className="master-pass"> Sports Book</span>
                                
                              </div>



                            </Accordion.Header>
                            <Accordion.Body className="p-0 py-1 px-sm-3 px-2">
                              {!isEmpty(singleBet?.eventName) &&
                                !isEmpty(singleBet?.type) &&
                                singleBet?.list?.length > 0 ? (
                                <div>

                                  <div className="current-bets-table p-2 border-0 single-match-body">
                                    {singleBet?.list?.map((item, index) => {
                                      return (
                                        <Table
                                          key={index}
                                          bordered
                                          className="rounded overflow-hidden bg-white mb-3 odds-table"
                                        >
                                          <tbody>
                                            <tr className="lay-back">
                                              <td colSpan="3">
                                                {" "}
                                                <span
                                                  className={`bet-name ${item?.type == "No"
                                                    ? "lay"
                                                    : item?.type == "Yes"
                                                      ? "back"
                                                      : item?.betType
                                                    }`}
                                                >
                                                  {startCase(item?.betType || item?.type)}{" "}
                                                </span>{" "}
                                                {item?.fancyName || item?.runnerName}  {">"}<span className="master-pass"> {item?.runnerName}</span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Odds.{" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {!isEmpty(item?.betType)
                                                    ? item?.bhav
                                                    : `${item?.betRun}/${item?.bhav}`}
                                                </strong>
                                              </td>
                                              <td>
                                                Stake (USD){" "}
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.amount}
                                                </strong>
                                              </td>
                                              <td>
                                              {item?.betType == "back"? "Profit (USD)":"Liability (USD)" }
                                              {item?.betType == "back"?
                                                <strong className="d-block">
                                                  {" "}
                                                  {item?.profitAmount}
                                                </strong>
                                                :
                                                <strong className="d-block" style={{color:'red'}}>
                                                  {" "}
                                                 ({item?.profitAmount})
                                                </strong>}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="" colSpan="3">
                                                <div className="d-flex justify-content-between">
                                                  Ref:{" "}
                                                  {item?.matchBetId
                                                    ? item?.matchBetId
                                                    : item?.sessionBetId || "--"}
                                                  <span>
                                                    {obj.dateFormat(item?.timeInserted)}
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : ""}
                            </Accordion.Body>
                          </Accordion.Item>
                        </>
                      )}


                    </Accordion>
                    
                  </>
                  </>
                );
              })
              : ""}
            
          </div>
        ) : (
          <div
            className="market-depth-body single-match-body-mybets d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            {" "}
            No Record Found
          </div>
        )
      ) : (
        <div className="market-depth-body single-match-body-mybets single-match-body-parlaymybets p-1" style={{ height: "600px" }}>
          {parly?.map((res, index) => {
            return (
              <div className="all-bets position-relative" key={res?._id}>
                <div
                  style={{ borderBottom: "8px solid #d4e0e5" }}
                  className={
                    showBetNew
                      ? "current-bets-table mt-3 show"
                      : "current-bets-table mt-3"
                  }
                >
                  <Table
                    bordered
                    className="rounded overflow-hidden bg-white mb-0 odds-table"
                  >
                    <thead>
                      <tr>
                        <th colSpan="5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>Ref : 1309dfjkgh2</span>
                            <span>{obj.dateFormat(res?.timeInserted)}</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="lay-back">
                        {!res?.isDeclared ? (
                          <td colSpan="3">
                            {" "}
                            <span
                              style={{ background: "#d4e0e5" }}
                              className={`bet-name`}
                            >
                            <span className="rounded-circle"></span>
                            <span className="rounded-circle"> </span>
                            <span className="rounded-circle"></span>
                            </span>
                            <span className="parlays">{res?.bets?.length == 1
                              ? "SINGLE"
                              : res?.bets?.length == 2
                                ? "DOUBLE"
                                : res?.bets?.length == 3
                                  ? "TREBLE"
                                  : "ACCMULATOR"}
                                  </span>
                            
                          </td>
                        ) : (
                          <td colSpan="3">
                            {" "}
                            <span
                              style={{ background: "#d4e0e5" }}
                              className={`bet-name`}
                            // Win -- style={{ background: "green", color: "white" }}
                            // Loss -- style={{ background: "red", color: "white" }}
                            >
                              {/* Loss */}
                              {/* Win */}
                              ---
                            </span>
                            Birminghum FC
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>
                          Odds{" "}
                          <strong className="d-block">{parseFloat(res?.bhav).toFixed(2)}</strong>
                        </td>
                        <td>
                          Stake (USD){" "}
                          <strong className="d-block">{parseFloat(res?.amount).toFixed(2)}</strong>
                        </td>
                        <td>
                          Profit (USD)
                          <strong className="d-block">{res?.profitAmount}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          Will be{" "}
                          <span style={{ fontWeight: "700" }}>Keep</span> at
                          in-play
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  {showBetNew == res?._id && (
                    <>
                      {res?.bets?.map((item) => {
                        return (
                          <Table className="bets-table  mb-0">
                            <thead>
                              <th colSpan="4">
                                {objConstant?.betCheckObj[item?.eventType]}
                                <BiSolidRightArrow
                                  style={{
                                    background: "#9cb1bd",
                                    padding: "0",
                                    boxShadow: "none",
                                    lineHeight:`3`
                                  }}
                                  className="arrow-icon"
                                />
                                <span
                                  style={{ background: "#9cb1bd" }}
                                  className=""
                                >
                                  {item?.matchName}
                                </span>
                                <BiSolidRightArrow
                                  style={{
                                    background: "#9cb1bd",
                                    padding: "0",
                                    boxShadow: "none",
                                  }}
                                  className="arrow-icon"
                                /><span
                                  style={{ background: "#9cb1bd" , lineHeight:`2` }}
                                  className=""
                                >
                                  {item?.fancyName}
                                </span>
                                <BiSolidRightArrow
                                  style={{
                                    background: "#9cb1bd",
                                    padding: "0",
                                    boxShadow: "none",
                                  }}
                                  className="arrow-icon"
                                />
                                <span
                                  style={{ background: "#9cb1bd",  lineHeight:`2` }}
                                  className=""
                                >
                                  {item?.runnerName}
                                </span>
                              </th>
                            </thead>
                            <tbody style={{ background: "#d4e0e5" }}>
                              <tr>
                                <td
                                  colSpan={4}
                                  style={{
                                    background: "#d4e0e5",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>

                                      {
                                        item?.isDeclared ?
                                          <>
                                            {item?.result == "loss" ?
                                              <span


                                                style={{ background: "red", color: "white" }}
                                                className={`bet-name`}
                                              >
                                                {/* Loss */}
                                                {/* Win */}
                                                {startCase(item?.result)}
                                              </span>
                                              : <span

                                                style={{ background: "green", color: "white" }}

                                                className={`bet-name`}
                                              >
                                                {/* Loss */}
                                                {/* Win */}
                                                {startCase(item?.result)}
                                              </span>
                                            }
                                          </>
                                          : "-"

                                      }


                                      <span style={{ fontWeight: "700",color:`#000` }}>
                                        {item?.runnerName}
                                      </span>
                                    </span>
                                    <div
                                      style={{
                                        fontWeight: 800,
                                        padding: "3px 8px",
                                        background: "#9cb1bd",
                                      }}
                                      className="text-black text-center font-bold bg-black-300 rounded"
                                    >
                                      {parseFloat(item?.bhav).toFixed(2)}
                                    </div>
                                  </div>
                                  {/* Odds req. <strong className="d-block">4.23</strong> */}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        );
                      })}
                    </>
                  )}
                </div>
                <button
                  style={{ background: "#9cb1bd" }}
                  className={
                    showBetNew == res?._id
                      ? "show-hide-bet arrow-up"
                      : "show-hide-bet"
                  }
                  onClick={() => handleShowBet(res?._id)}
                >
                  <FaAngleDown />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
    // </OutsideClickHandler>
  );
};

export default MyBets;
