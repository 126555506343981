import React, { useEffect, useState } from "react";
import { GiSoccerBall, GiTennisBall } from "react-icons/gi";
import { AiFillTrophy } from "react-icons/ai";
import { isEmpty } from "lodash";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const GameMenu = ({ game, setGame, count }) => {
  useEffect(() => {
    if ((game)) {
      setGame("cricket")
    }
  }, []);
  const navigate = useNavigate();
  const [select, setSelect] = useState(false);
  const functionNew = () => {
    setSelect(true);
    let timer = setTimeout(() => {
      setSelect(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <div className="game-menu">
      <div className="w-100 h-100 d-flex justify-content-between align-items-center">
        <ul className="p-0 mb-0 w-100 game-menu-inner  d-flex justify-content-between align-items-center">
          <li
            id={"casino"}
            onClick={() => {
              setGame("casino");
              let element = document.getElementById("casino");
              let rect = element.getBoundingClientRect();
              navigate("/casino")
            }}
            className={game == "casino" ? "active" : ""}
            style={{color:`#f22c8f`}}
          >
            <div>
             <img src="../assets/images/chip.webp" style={{width:`29px`}}/>
            </div>
            Casino
          </li>
          <li
            id={"myFav"}
            onClick={() => {
              setGame("myFav");
              // let element = document.getElementById("myFav");
              // let rect = element.getBoundingClientRect();
            }}
            className={game == "myFav" ? "active" : ""}
          >
            <div>
              <FaStar />
            </div>
            My Fav
          </li>
          <li
            id={"cricket"}
            onClick={() => {
              setGame("cricket");
              // let element = document.getElementById("cricket");
              // let rect = element.getBoundingClientRect();
            }}
            className={game == "cricket" ? "active" : ""}
          >
            <div>
            <i  class="icon-cricket" style={{fontSize:`18px`}}></i>
            </div>
            <span>{count?.cricketInplayCount}</span>
            Cricket
          </li>
          <li
            id={"soccer"}
            onClick={() => {
              setGame("soccer");
              // let element = document.getElementById("soccer");
              // let rect = element.getBoundingClientRect();
            }}
            className={game == "soccer" ? "active" : ""}
          >
            <div>
              <GiSoccerBall />
            </div>
            <span>{count?.soccerInplayCount}</span>
            Soccer
          </li>
          <li
            id={"tennis"}
            onClick={() => {
              setGame("tennis");
              // let element = document.getElementById("tennis");
              // let rect = element.getBoundingClientRect();
            }}
            className={game == "tennis" ? "active" : ""}
          >
            <div>
              <GiTennisBall />
            </div>
            <span>{count?.tennisInplayCount}</span>
            Tennis
          </li>
          {/* <div class="animation start-home"></div> */}
        </ul>
        {/* <div
          style={{
            transform: `translateX(${select2 - 10}px)`,
            opacity: select > 0 ? 1 : 0,
            width:
              witdthHeight?.width > 0 ? `${witdthHeight?.width}px` : "84px",
            height:
              witdthHeight?.height > 0 ? `${witdthHeight?.height}px` : "79px",
          }}
          className="tab-indicator-new"
        ></div> */}
      </div>
    </div>
  );
};

export default GameMenu;
